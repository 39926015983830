import { Row, Col, Card, Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../../consts';
import { useEffect, useState } from 'react';

const Terraria = (serverInfo) => {
    const navigate = useNavigate();
    const [serverState, setServerState] = useState(serverInfo || null);
    const [seed, setSeed] = useState('');
    const [worldName, setWorldName] = useState('');
    const [worldSize, setWorldSize] = useState(2);
    const [difficulty, setDifficulty] = useState(0);
    const [motd, setMotd] = useState('Fortune & Glory, Kid.');
    const [password, setPassword] = useState('');
    const [instId, setInstId] = useState('');
    const [user, setUser] = useState('');
    const [show, setShow] = useState(false);
    window.history.replaceState(null, "Mahi", "/create/terraria")

    const loadUser = () => {
        fetch(`${API_URL}/profile`, {
          method: 'POST',
          credentials: 'include'
        }).then(res => res.json())
          .then(user => {
            setUser(user);
        }).catch(e => {});
    }
    useEffect(loadUser, []);

    const Denied = () => {
        return (
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body>You must login to create a server!</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShow(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    // terraria create world:
    // name
    // seed
    // size: small, medium or large
    // difficulty: journey, classic, expert, master
    // random, corruption, crimson

    const createServer = (server) => {
        fetch(`${API_URL}/servers/create`, {
            method: 'POST',
            body: JSON.stringify(server),
            headers: {
              'content-type': 'application/json'
            }
          }).then(status => status.json())
            .then(() => navigate('/servers'))
            .catch(() => {});
    }

    const createInstance = (server) => {
        fetch(`${API_URL}/instances`, {
            method: 'POST',
            body: JSON.stringify(server),
            headers: {
                'content-type': 'application/json'
            }
        }).then(status => status.json());
    }

    const createInstance2 = (server) => {
        if (user) {
            fetch(`${API_URL}/instances`, {
                method: 'POST',
                body: JSON.stringify(server),
                headers: {
                    'content-type': 'application/json'
                }
            }).then(status => status.json())
                .then(res => createServer({...server, instId: res.instance.id}));
        }
        else {
            setShow(true);
        }
    }

    const serverAndInstanceInfo = () => {
        if (serverInfo.serverInfo && user) {
            return ({
                game: serverInfo.serverInfo.game,
                version: serverInfo.serverInfo.version,
                seed: seed,
                worldSize: worldSize,
                worldName: worldName,
                difficulty: difficulty,
                motd: motd,
                password: password,
                instId: instId,
                owner: user.username,
            });
        }
    }

    if (serverInfo.serverInfo) {
        return (
            <>
                <Row>
                    <Col />
                    <Col>
                        <Card className='mt-1 mb-1'>
                            <Card.Text style={{textAlign: 'center'}}>
                                <b>Game:</b> {serverInfo.serverInfo.game} <br/>
                                <b>Version:</b> {serverInfo.serverInfo.version} <br/>
                            </Card.Text>
                        </Card>
                        <Card className='mt-1 mb-1'>
                            <Card.Text style={{textAlign: 'center'}}>
                                <b>World Name:</b>
                                <Form className='mt-1 mb-1 ms-1 me-1'>
                                    <Form.Control type='text'
                                        onChange={(event) => setWorldName(event.target.value)} placeholder='Enter world name...' aria-label='world'/>
                                </Form>
                                <hr/>
                                <b>World Seed:</b>
                                <Form className='mt-1 mb-1 ms-1 me-1'>
                                    <Form.Control type='text'
                                        onChange={(event) => setSeed(event.target.value)} placeholder='Enter world seed...' aria-label='seed'/>
                                </Form>
                                <hr/>
                                <b>MOTD:</b>
                                <Form className='mt-1 mb-1 ms-1 me-1'>
                                    <Form.Control type='text'
                                            onChange={(event) => setMotd(event.target.value)} placeholder='Enter motd...' aria-label='motd'/>
                                </Form>
                                <hr/>
                                <b>Password:</b>
                                <Form className='mt-1 mb-1 ms-1 me-1'>
                                    <Form.Control type='text'
                                            onChange={(event) => setPassword(event.target.value)} placeholder='Enter server password...' aria-label='password'/>
                                </Form>
                            </Card.Text>
                        </Card>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <Card className='mt-1 mb-1'>
                            <Card.Text>
                                <b>World Size:</b>
                                <ListGroup className='mt-1 mb-1 ms-1 me-1'>
                                    <ListGroup.Item style={{fontWeight: 'normal'}} action active={worldSize === 1} onClick={() => setWorldSize(1)}>Small</ListGroup.Item>
                                    <ListGroup.Item style={{fontWeight: 'normal'}} action active={worldSize === 2} onClick={() => setWorldSize(2)}>Medium</ListGroup.Item>
                                    <ListGroup.Item style={{fontWeight: 'normal'}} action active={worldSize === 3} onClick={() => setWorldSize(3)}>Large</ListGroup.Item>
                                </ListGroup>
                                <hr/>
                                <b>Difficulty:</b>
                                <ListGroup className='mt-1 mb-1 ms-1 me-1'>
                                    <ListGroup.Item style={{fontWeight: 'normal'}} action active={difficulty === 3} onClick={() => setDifficulty(3)}>Journey</ListGroup.Item>
                                    <ListGroup.Item style={{fontWeight: 'normal'}} action active={difficulty === 0} onClick={() => setDifficulty(0)}>Classic</ListGroup.Item>
                                    <ListGroup.Item style={{fontWeight: 'normal'}} action active={difficulty === 1} onClick={() => setDifficulty(1)}>Expert</ListGroup.Item>
                                    <ListGroup.Item style={{fontWeight: 'normal'}} action active={difficulty === 2} onClick={() => setDifficulty(2)}>Master</ListGroup.Item>
                                </ListGroup>
                            </Card.Text>
                        </Card>
                    </Col>
                    <Col />
                </Row>
                <Row>
                    <Col />
                    <Col style={{textAlign: 'center'}}>
                        <Button className='mt-1 mb-1 ms-1 me-1' onClick={() => {
                            createInstance2(serverAndInstanceInfo());}}>
                            Create
                        </Button>
                    </Col>
                    <Col />
                </Row>
                <Denied />
            </>
        );
    }
    return (
        <>go back</>
    );
}

export default Terraria;