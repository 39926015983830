import { ListGroup, Row, Col, Button, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import './create.css';
import mcIcon from './minecraft-icon.png';
import terrariaIcon from './terraria-icon.png';
import { API_URL } from '../../consts';
import Terraria from './Terraria';

const Create = () => {
    const [games, setGames] = useState({});
    const [game, setGame] = useState(null);
    const [version, setVersion] = useState(null);
    const [ready, setReady] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const navigate = useNavigate();
    useEffect(() => loadGames(), []);

    const loadGames = () => {
        fetch(API_URL + '/games')
        .then(res => res.json())
        .then(games => setGames(games))
        .catch(e => {});
    }

    useEffect(() => {
        if (game && version) {
            setReady(true);
        }
        else {
            setReady(false);
        }
    });

    const changeGame = (selectedGame) => {
        setGame(selectedGame);
        setVersion(null);
    }

    const NextButton = () => {
        if (ready) {
            return (
                <Button onClick={() => setConfirm(true)}>Next
                    <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
                </Button>
            );
        }
        return (
            <></>
        );
    }

    const GameIcon = () => {
        if (game === 'minecraft') {
            return (
                <>
                    <img src={mcIcon} alt="" width='24' height='24' className='mb-1'/>
                </>
            );
        }
        if (game === 'terraria') {
            return (
                <>
                    <img src={terrariaIcon} alt="" width='24' height='24' className='mb-1'/>
                </>
            );
        }
        return (
            <></>
        );
    }

   const GameList = () => {
    return (
        <>
            {games.map(gm => {
                return(
                    <ListGroup.Item className='jim-list-group' action active={game === gm.name} onClick={() => changeGame(gm.name)}>
                        {gm.name.substring(0,1).toUpperCase() + gm.name.substring(1)}
                    </ListGroup.Item>
                );
            })}
        </>
    );
   }

    const GameSelect = () => {
        if (game) {
            const selectedGame = game.substring(0,1).toUpperCase() + game.substring(1);
            return (
                <>
                    <Row>
                        <Col>
                            <div style={{fontWeight:'bold',}}>Game: <GameIcon /> {selectedGame}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup>
                                <GameList />
                            </ListGroup>
                        </Col>
                    </Row>
                </>
            );
        }
        if (Object.keys(games).length > 0) {
            return (
                <>
                    <div style={{fontWeight:'bold',}}>Select a game!</div>
                    <ListGroup>
                        <GameList />
                    </ListGroup>
                </>
            );
        }
        return (
            <>
                <div style={{fontWeight:'bold',}}>Select a game!</div>
                Loading games...
            </>
        );
    }

    const VersionSelect = () => {
        if (game) {
            const vsns = games.find(gm => gm.name === game).versions;
            return (
                <>
                    <Row className='mb-1'>
                        <div style={{fontWeight:'bold',}}>Version: {version}</div>
                    </Row>
                    <Row>
                        <ListGroup>
                            {vsns.map(vsn => {
                                return (
                                    <ListGroup.Item className='jim-list-group' action active={version === vsn} onClick={() => setVersion(vsn)}>
                                        {vsn}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </Row>
                </>
            );
        }
        return (
            <></>
        );
    }

    if (confirm) {
        return (
            <Terraria serverInfo={{
                game: game,
                version: version,
                owner: '',
            }} />
        );
    }

    return (
        <>
            <Row>
                <Col/>
                <Col>
                    <GameSelect />
                </Col>
                <Col>
                    <VersionSelect />
                </Col>
                <Col/>
            </Row>
            <Row>
                <Col className='jim-nextbutton mt-3'><NextButton /></Col>
            </Row>
        </>
    );
}

export default Create;