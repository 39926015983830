import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../consts";

function Register() {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    const register = () => {
        fetch(`${API_URL}/register`, {
          method: 'POST',
          body: JSON.stringify(user),
          credentials: 'include',
          headers: {
            'content-type': 'application/json'
          }
        }).then(status => navigate('/'))
          .catch(() => {});
      };

    return (
        <>
            <Row>
                <Col md xs="auto" />
                <Col>
                    <div>
                    <h1>Register</h1>
                    <input
                        value={user.username}
                        onChange={(e) => setUser({...user, username: e.target.value})}
                        placeholder="username"
                        className="form-control mt-2 mb-2"/>
                    <input
                        value={user.password}
                        onChange={(e) => setUser({...user, password: e.target.value})}
                        placeholder="password"
                        type="password"
                        className="form-control mt-2 mb-2"/>
                    <button
                        className="btn btn-dark"
                        onClick={register}>
                        Register
                    </button>
                    </div>
                </Col>
                <Col md sm="auto" />
            </Row>
        </>
    );
}

export default Register;