import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    </>
  );
}

export default Footer;
