import { Nav, Navbar, Button, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faWater } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../consts';
import { useState, useEffect } from 'react';

import './navigation.css';

const Navigation = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState();

    const loadNav = () => {
        fetch(`${API_URL}/profile`, {
          method: 'POST',
          credentials: 'include'
        }).then(res => res.json())
          .then(user => {
            setUser(user);
        }).catch(e => {});
    }
    useEffect(loadNav, [navigate]);

    const logout = () => {
        fetch(`${API_URL}/logout`, {
          method: 'POST',
          credentials: 'include'
        }).then(res => window.location.reload(true));
    }

    const User = () => {
        if (user) {
            return (
                <>
                    <Card className='mb-1 me-1' style={{height: '40px'}}><div className='mt-2 ms-3 me-3'>Hi, {user.username}!</div></Card>  
                    <Button className='mb-1 me-1' variant='outline-dark'
                        onClick={logout}>Logout</Button>
                </>
            );
        }
        return (
            <>
                <Link to="/login" className="wd-nav-link">
                    <Button variant="outline-dark">Sign In</Button>
                </Link>
                <Link to="/register" className="wd-nav-link ms-1">
                    <Button variant="dark">Create Profile</Button>
                </Link>
            </>
        );
    }

    return (
        <>
            <Navbar expand="lg">
                <Navbar.Brand>
                    <Link to="/" className="wd-nav-link">
                        <FontAwesomeIcon icon={faWater} className="me-2" />
                        Mahi
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                    <Nav.Link>
                        <Link to="/create" className="wd-nav-link">Create</Link>
                    </Nav.Link>
                    <Nav.Link>
                        <Link to="/servers" className="wd-nav-link">Servers</Link>
                    </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <User />
            </Navbar>
        </>
    );
}

export default Navigation;
