import { Row, Col, Alert, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_URL } from '../../consts';

const Home = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const loadHome = () => {
      fetch(`${API_URL}/profile`, {
        method: 'POST',
        credentials: 'include'
      }).then(res => res.json())
        .then(user => {
          setUser(user);
      }).catch(e => {});
    }
    useEffect(loadHome, []);

    const User = () => {
      if (user) {
        return (
          <>{user.username}</>
        );
      }
      return (<></>);
    }

    return (
        <>
          <Row>
            <Alert style={{textAlign: 'center'}} variant='secondary'>Disclaimer: This is the Alpha version of Mahi. Not all features are implemented and there may be some bugs.</Alert>
          </Row>
          <Row>
            <Col />
            <Col style={{textAlign: 'center'}}>
              <p>Welcome!<br/><br/>
                Mahi is a space to create remote Terraria servers automatically.<br/><br/>
                While Terraria is currently the only supported game, we plan to add Minecraft and others in the future.<br/><br/>
                Get started by clicking the button below.<br/></p>
              <Button onClick={() => navigate('/create')}>Get Started</Button>
            </Col>
            <Col />
          </Row>
        </>
    );
}

export default Home;
