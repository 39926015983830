const initialState = {}

const servers = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SERVERS':
            return(action.servers);
        default:
            return(state);
    }
}

export default servers;