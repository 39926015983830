const initialState = {}

const instances = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_INSTS':
            return(action.insts);
        default:
            return(state);
    }
}

export default instances;