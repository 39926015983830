import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const Server = () => {
    const params = useParams();
    const serverId = params.serverId || null;
    const [server, setServer] = useState(null);
    const [time, setTime] = useState('Coming Soon!');
    const [insts, setInsts] = useState(null);

    const fetchServer = () => {
        if (serverId) {
            fetch(API_URL + `/servers/${serverId}`)
            .then(svr => svr.json())
            //.then(svr => console.log(svr))
            .then(svr => setServer(svr));
        }
    }

    const fetchInsts = () => {
        fetch(API_URL + '/instances')
            .then(insts => insts.json())
            .then(insts => setInsts(insts));
    }

    const fetchSetTime = (time) => {
        var ip = '';
        insts.instances.map(element => {
            if (element.id === server.instId) {
                ip = element.main_ip;
            }
        });
        setTime(time);
        fetch(`${API_URL}/command/time`, {
            method: 'POST',
            body: JSON.stringify({
                time: time,
                host: ip,
            }),
            credentials: 'include',
            headers: {
              'content-type': 'application/json'
            }
          }).then(res => res.json())
            .catch(() => {});
    }

    useEffect(() => {fetchServer();fetchInsts();}, []);

    if (server && insts) {
        return (
            <>
                <Row>
                    <Col></Col>
                    <Col xs={4}>
                        <Card style={{textAlign: 'center'}}>
                            <Card.Header>
                                Server Info
                            </Card.Header>
                            <Card.Text className='ms-1 me-1 mt-1 mb-1'>
                                <b>Name:</b> {server.worldName} <br/>
                                <b>Game:</b> {server.game} <br/>
                                <b>Version:</b> {server.version} <br/>
                                <b>IP:</b> {insts.instances.map(i => {if (i.id === server.instId) {return(i.main_ip);}})}
                            </Card.Text>
                        </Card>
                    </Col>
                    <Col xs={4}>
                        <Card style={{textAlign: 'center'}}>
                            <Card.Header>
                                Set Time
                            </Card.Header>
                            <Card.Text>
                                <Button disabled onClick={() => setTime('Coming soon!')} variant='outline-dark' className='ms-1 me-1 mt-1 mb-1'>Dawn</Button>
                                <Button disabled onClick={() => setTime('Coming soon!')} variant='outline-dark' className='ms-1 me-1 mt-1 mb-1'>Noon</Button>
                                <Button disabled onClick={() => setTime('Coming soon!')} variant='outline-dark' className='ms-1 me-1 mt-1 mb-1'>Dusk</Button>
                                <Button disabled onClick={() => setTime('Coming soon!')} variant='outline-dark' className='ms-1 me-1 mt-1 mb-1'>Midnight</Button>
                            </Card.Text>
                        </Card>
                        <div style={{textAlign: 'center'}}>{time}</div>
                    </Col>
                    <Col></Col>
                </Row>
            </>
        );
    }

    return (
        <>Invalid Server!</>
    );
}

export default Server;