import logo from './logo.svg';
import './App.css';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { API_URL } from './consts';

import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Login from './Components/Login';
import Register from './Components/Register';
import Create from './Components/Create';
import Terraria from './Components/Create/Terraria';
import ServerList from './Components/ServerList';
import Server from './Components/Server';
import NotFound from './Components/NotFound';

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const loadApp = () => {
    fetch(`${API_URL}/profile`, {
      method: 'POST',
      credentials: 'include'
    }).then(res => res.json())
      .then(user => {
        setUser(user);
    }).catch(e => {});
  }
  useEffect(loadApp, [navigate]);

  if (user) {
      return (
        <Container fluid className="jimbot-App">
        <Row className="ms-4 me-4">
          <Navigation />
          <hr/>
        </Row>
        <Row className="ms-4 me-4 mt-4 mb-4">
          <Routes>
            <Route path="create" element={<Create />}/>
            <Route path="create/Terraria" element={<Terraria />}/>
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="servers" element={<ServerList />} />
            <Route path="servers/:serverId" element={<Server />} />
            <Route component={<NotFound />} />
          </Routes>
        </Row>
        <Row className="ms-4 me-4">
          <Footer />
        </Row>
        </Container>
      );
    }
  return (
    <>
      <Row className='mt-5'>
        <Col>
          <Login/>
        </Col>
      </Row>
    </>
  );
}

export default App;
