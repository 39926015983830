import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../consts';
import { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [captcha, setCaptcha] = useState(false);
    //const [captcha, setCaptcha] = useState(true);

    const login = () => {
        if (captcha) {
            fetch(`${API_URL}/login`, {
                method: 'POST',
                body: JSON.stringify(user),
                credentials: 'include',
                headers: {
                  'content-type': 'application/json'
                }
              }).then(status => {
                  window.location.reload(true)
              });
        }
    }

    return (
        <>
            <div className="jimbot-Login">
                <Row>
                    <Col md xs="auto"/>
                    <Col>
                        <div>
                        <h1>Sign In</h1>
                        <input
                            value={user.username}
                            onChange={(e) => setUser({...user, username: e.target.value})}
                            placeholder="username"
                            className="form-control mt-2 mb-2"/>
                        <input
                            value={user.password}
                            onChange={(e) => setUser({...user, password: e.target.value})}
                            placeholder="password"
                            type="password"
                            className="form-control mt-2 mb-2"/>
                        <button
                            onClick={login}
                            className="btn btn-dark">
                            Login
                        </button>
                        <ReCAPTCHA className="mt-1" sitekey="6LcImgApAAAAAFeSTlQfGeay4sJQCveZ-SeOD4V9" onChange={() => setCaptcha(true)} />
                        </div>
                    </Col>
                    <Col md sm="auto"/>
                </Row>
            </div>
        </>
    );
}

export default Login;
