import { Button, Table, Modal, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_URL } from '../../consts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowUpRightFromSquare, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServers } from '../../Services/serverService';
import { fetchInstances } from '../../Services/instanceService';

const ServerList = () => {
    //const [servers, setServers] = useState(null);
    //const [instances, setInstances] = useState({});
    const [show, setShow] = useState(false);
    const [serverToDelete, setServerToDelete] = useState(null);
    const [instToDelete, setInstToDelete] = useState(null);
    const [currIp, setCurrIp] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const servers = useSelector(state => state.servers);
    const instances = useSelector(state => state.instances);

    useEffect(() => {fetchServers(dispatch);fetchInstances(dispatch)}, []);

    const findServerIp = (instanceId) => {
        Object.entries(instances).forEach((instance) => {
            if (instance.id === instanceId) {
                setCurrIp(instance.main_ip);
            }
        });
    }

    /*const fetchServers = () => {
        fetch(API_URL + '/servers')
        .then(res => res.json())
        .then(servers => setServers(servers))
        .catch(e => {});
    }*/

    /*const fetchInstances = () => {
        fetch(API_URL + '/instances')
        .then(res => res.json())
        .then(insts => setInstances(insts))
        .catch(e => console.error(e));
    }*/

    const fetchInstanceById = (id) => {
        fetch(API_URL + '/instances/' + id, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(res => res.json());
    }

    const deleteServer = (serverId) => {
        fetch(API_URL + '/servers/' + serverId, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json())
            .then(() => navigate('/'));
    }

    const deleteInstance = (instanceId) => {
        fetch(API_URL + '/instances/' + instanceId, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json());
    }

    const confirmDelete = (instanceId, serverId) => {
        setInstToDelete(instanceId);
        setServerToDelete(serverId);
        setShow(true);
    }

    if (servers && instances.instances) {
        if (Object.keys(servers).length === 0) {
            return (
                <div style={{textAlign: 'center'}}>
                    Create a server to view it here.<br/>
                    <Button variant='outline-dark' onClick={() => navigate('/create')}>Go to Create Page</Button>
                </div>
            );
        }
        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <th>Game</th>
                            <th>Name</th>
                            <th>Owner</th>
                            <th>Version</th>
                            <th>Status</th>
                            <th>IP</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {servers.map((svr) => {
                            const serverLink = '/servers/' + svr._id;
                            return (
                                <tr>
                                    <td>{svr.game}</td>
                                    <td><i>{svr.worldName}</i></td>
                                    <td>{svr.owner}</td>
                                    <td>{svr.version}</td>
                                    <td>{instances.instances.map((inst) => {
                                            if (inst.id === svr.instId) {
                                                if (inst.status === 'active') {
                                                    return (<FontAwesomeIcon className='ms-3' style={{color: "#57e389",}} icon={faCircleCheck}/>);
                                                }
                                                else {
                                                    return (<FontAwesomeIcon className='ms-3' icon={faCircleXmark} />);
                                                }
                                            }   
                                        })}</td>
                                    <td>
                                        {instances.instances.map((inst) => {
                                            var count = 0;
                                            if (inst.id === svr.instId) {
                                                return (<>{inst.main_ip}</>);
                                            } else {
                                                count += 1;
                                                if (count < 1) {
                                                    return (<Spinner animation='border' />);
                                                }
                                            }
                                        })}
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        <Link to={serverLink}><Button variant='outline-primary' className='mb-1 me-1'>
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Button></Link>
                                        <Button variant='outline-danger' className='mb-1 me-1' onClick={() => confirmDelete(svr.instId, svr._id)}>
                                            <FontAwesomeIcon icon={faTrash}/></Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Body>Are you sure you want to delete this server?</Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => setShow(false)}>Cancel</Button>
                            <Button variant='danger' onClick={() => {deleteInstance(instToDelete);deleteServer(serverToDelete);setShow(false);}}>
                                Delete
                            </Button>
                        </Modal.Footer>
                </Modal>
            </>
        );
    }

    return (
        <div style={{textAlign: 'center'}}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default ServerList;